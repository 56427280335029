import gapi from '@/functions/googleCalendar.js';

const state = {
  appLoaded: false,
  currentRoute: '',
  pageWidth: 0,
  pageHeight: 0,
  menuItems: [
    {
      name: 'fitting',
      text: 'FITTING'
    },
    {
      name: 'community',
      text: 'COMMUNITY'
    },
    {
      name: 'about',
      text: 'ABOUT'
    },
    {
      name: 'contact',
      text: 'CONTACT'
    }
  ]
};

const getters = {
  getAppLoaded: (state) => state.appLoaded,
  getCurrentRoute: (state) => state.currentRoute,
  getPageWidth: (state) => state.pageWidth,
  getPageHeight: (state) => state.pageHeight,
  getMenuItems: (state) => state.menuItems,
};

const mutations = {
  setAppLoaded(state, data) {
    state.appLoaded = data
  },
  setCurrentRoute(state, data) {
    state.currentRoute = data;
  },
  setPageDims(state, data) {
    let { width, height } = data
    state.pageWidth = width,
    state.pageHeight = height
  }
};

const actions = {
  async loadApp({ commit }) {
    const initCB = () => {
      commit('setAppLoaded', true)
    }
    gapi.initialise(initCB)
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
