<template>
  <div id="menu">

    <div class="nav"
         :class="`${menuOpen ? 'open' : 'close'}`"
    >
      <div v-if="currentRoute !== 'home'" class="nav-item logo">
        <router-link :to="{ name: 'home' }">
          <img src="@/assets/icons/matisa-logo_lime.png" alt="link to home">
        </router-link>
      </div>

      <div class="menu-dropdown"
           @click="toggleMenu"
           @focusout="closeMenu"
      >
        <img src="@/assets/icons/arrow-hover.svg"
             class="menu-button"
             alt="open/close menu button"
        />
      </div>
      <div class="nav-items menu" v-if="menuOpen">
        <router-link v-for="item in menuItems"
                     :key="item.name"
                     :to="{ name: item.name }"
                     :class="currentRoute === item.name ? 'current' : ''"
        >
          {{item.text}}
        </router-link>
      </div>
     </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Menu',
  data() {
    return {
      menuOpen: false
    }
  },
  computed: {
    ...mapGetters({
      'menuItems': 'getMenuItems',
      'pageWidth': 'getPageWidth',
      'currentRoute': 'getCurrentRoute'
    }),
    ifDesktop() {
      return this.pageWidth > 1000
    }
  },
  watch: {
    currentRoute() {
      if(!this.ifDesktop) {
        this.menuOpen = false
      }
    },
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen
    },
    closeMenu() {
      this.menuOpen = false
    },
  },
  mounted() {
    if(this.ifDesktop) this.menuOpen = true
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#app:not(.home) #menu {

}
/* navigation ------------ */
#menu {
  position: fixed;
  left: 0;
  top: 0;
  text-align: right;
  overflow: hidden;
  z-index: 100;
  width: calc(100% - 10px);
}
  a {
    display:inline;
    list-style-type: none;
    font-size: 60%;
    font-weight: 900;
    letter-spacing: 3px;
    margin: 0;
    padding: 0;
  }
  .nav {
    display: grid;
    grid-template-columns: auto auto calc(var(--menu-height) + 0.5rem);
    align-items: center;
    padding: 1rem;
    padding-right: calc(1rem);
    grid-gap: 1rem;
    transition: all 0.25s ease;
  }
  .nav.open {
    background-image: linear-gradient(to bottom right, rgba(0,0,0,0.7), rgba(0,0,0,0));
  }
  #app:not(.home) .nav {
    background-image: linear-gradient(to bottom right, rgba(0,0,0,0.7), rgba(0,0,0,0));
  }
  .menu-dropdown {
    grid-column: 3/4;
    display: grid;
    justify-items: flex-end;
    cursor: pointer;
  }
  .menu-dropdown .menu-button {
    height: var(--menu-height);
    transition: all 0.25s ease;
  }
  .nav.close .menu-dropdown .menu-button {
    transform: rotate(180deg);
  }
  .nav-items {
    /* padding: 1rem; */
  }
  .nav-items.menu a {
    padding-left: 1.5rem;
    font-size: var(--main-text);
    font-weight: 900;
  }
  .nav-items .current {
    color: var(--lime-green);
  }
  .logo {
    text-align: left;
    filter: brightness(1000%);
    transition: all 0.2s ease;
    grid-row: 1/2;
    grid-column: 1/2;
  }
  .logo:hover {
    filter: brightness(100%);
  }
  .logo img {
    height: calc(var(--menu-height)*0.5);
  }
  @media only screen and (max-width: 1000px) {
    .nav.open {
      background-image: linear-gradient(180deg, rgba(0,0,0,0.95), rgba(0,0,0,0.8), rgba(0,0,0,0.5)) !important;
    }
    .nav-items {
      padding: 1rem 2rem;
      display: grid;
      grid-column: span 3;
      grid-gap: 3rem;
      text-align: center;
      font-size: 150%;
      padding-bottom: 3rem;
    }
  }

  @media only screen and (min-width: 1000px) {
    .nav-items {
      grid-row: 1/2;
      grid-column: 2/3;
      max-width: 100%;
      overflow-x: auto;
    }
  }

</style>
