export default [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home "*/ "@/pages/Home.vue"),
    meta: {
      title: 'Matisa Bicycles | Custom Bespoke Frames',
      metaTags: [
        {
          name: 'description',
          content: 'ONE OF A KIND CUSTOM BUILT STEEL FRAMES. Hand crafted in Australia. Matisa is the home of handmade steel bicycle frames, available as a frameset, partial bike build or complete bike. Its all in the detail.'
        }
      ]
    }
  },
  {
    path: "/about",
    name: "about",
    component: () => import(/* webpackChunkName: "home "*/ "@/pages/About.vue"),
    meta: {
      title: 'Matisa Bicycles | About Us',
      metaTags: [
        {
          name: 'description',
          content: 'Originally known as Venom Cycles in Birmingham, Matisa continues the legacy of producing high quality, precision made, modern steel bicycles. Over 30 years, Matisa (and Venom) has placed more than 1000 hand built frames on to the roads, trails and tracks of the world.'
        }
      ]
    }
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import(/* webpackChunkName: "home "*/ "@/pages/Contact.vue"),
    meta: {
      title: 'Matisa Bicycles | Contact Us',
      metaTags: [
        {
          name: 'description',
          content: 'Contact us to make a booking or enquire further about our services.'
        }
      ]
    }
  },
  {
    path: "/fitting",
    name: "fitting",
    component: () => import(/* webpackChunkName: "home "*/ "@/pages/Fitting.vue"),
    meta: {
      title: 'Matisa Bicycles | Bike Fitting',
      metaTags: [
        {
          name: 'description',
          content: 'Custom bike fitting so you can get the perfect fit for your next bike.'
        }
      ]
    }
  },
  {
    path: "/book-a-fitting",
    name: "book-a-fitting",
    component: () => import(/* webpackChunkName: "home "*/ "@/pages/BookAFitting.vue"),
    meta: {
      title: 'Matisa Bicycles | Book a Fitting',
      metaTags: [
        {
          name: 'description',
          content: 'Book an appointment for custom bike fitting at Matisa.'
        }
      ]
    }
  },
  {
    path: "/community",
    name: "community",
    component: () => import(/* webpackChunkName: "home "*/ "@/pages/Community.vue"),
    meta: {
      title: 'Matisa Bicycles | Community',
      metaTags: [
        {
          name: 'description',
          content: 'Updates from the Matisa Bicycles Community.'
        }
      ]
    }
  },
  // {
  //   path: "/space/:roomId",
  //   name: "space",
  //   component: () =>
  //     import(/* webpackChunkName: "roomPage "*/ "@/pages/RoomPage.vue"),
  // },
  {
    path: "*",
    name: "404",
    component: () =>
      import(/* webpackChunkName: "404Page "*/ "@/pages/404Page.vue"),
    meta: {
      title: 'Page Not Found',
      metaTags: [
        {
          name: 'description',
          content: 'Page not found'
        }
      ]
    }
  },
];
