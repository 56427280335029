import Vue from "vue";
import Vuex from "vuex";
import root from "./modules/root";
import home from "./modules/home";
import community from "./modules/community";

// create connection ------------ //
Vue.use(Vuex);

// collection of all modules --------------//
// allowing access from vue
export default new Vuex.Store({
  modules: {
    root,
    home,
    community
  },
});
