<template>
  <div class="loading">
      <img src="@/assets/icons/loading.svg" alt="loading" />
      <span v-if="waiting">If this doesn't load within a few seconds please refresh :)</span>
  </div>
</template>

<script>
export default {
  name: 'LoadingIframe',
  data() {
    return {
      waiting: false
    }
  },
  methods: {
    checkIfWaiting() {
      if(this.loading) {
        this.waiting = true
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.checkIfWaiting()
    }, 1500)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.loading {
  height: 100%;
  grid-row: 1/3;
  grid-column: 1/2;
  display: grid;
  align-content: center;
  justify-items: center;
  padding: 0 2rem;
  grid-gap: 2rem;
}
.loading img {
  height: 2.2rem;
  width: auto;
  animation: rotate 2.2s linear infinite;
}

@keyframes rotate {
  0% {transform: rotate(0deg)}
  100% {transform: rotate(360deg)}
}
</style>
