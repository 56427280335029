import routes from "./routes";
import VueRouter from "vue-router";

const router = new VueRouter({
    routes,
    scrollBehavior () {
      document.getElementById('app').scrollTo(0,0)
    }
});


export default router;
