<template>
  <div id="app" :class="currentRoute">

    <div v-if="appLoaded">
      <Menu />

      <section id="content">
        <router-view />
      </section>
    </div>
    <loading v-else />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Menu from '@/components/global/Menu.vue'
import Loading from '@/components/global/Loading.vue'

export default {
  name: 'App',
  components: {
    Menu,
    Loading,
  },
  computed: {
    ...mapGetters({
      'appLoaded': 'getAppLoaded',
    }),
    currentRoute() {
      return this.$store.getters.getCurrentRoute
    },
  },
  watch: {
    '$route' (to) {
      this.$store.commit('setCurrentRoute', to.name)
    },
  },
  methods: {
    ...mapActions(['loadApp']),
    getPageDims() {
      this.$store.commit('setPageDims', {
        width: window.innerWidth,
        height: window.innerHeight
      })
    },
  },
  created() {
    this.loadApp()
    this.getPageDims()
    window.addEventListener('resize', this.getPageDims)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getPageDims)
  }
}
</script>

<style>
@font-face {
  font-family: EurostileBold;
  src: url("./assets/fonts/EurostileBold.ttf") format("truetype"),
  url('assets/fonts/EurostileBold.ttf') format('truetype');
}
@font-face {
  font-family: Eurostile;
  src: url("./assets/fonts/Eurostile.ttf") format("truetype"),
  url('assets/fonts/Eurostile.ttf') format('truetype');
}

#app {
  height: 100vh;
  display: grid;
  grid-template-rows: var(--menu-height) auto;
  grid-template-columns: 1fr;
  grid-gap: 0;
  overflow-y: scroll;
  overflow-x: hidden;
}
#content {
  height: 100%;
  grid-row: 1/3;
}
h1,h2,h3,h4,h5,h6,a {
  font-family: EurostileBold, sans-serif;
}
p,span {
  font-family: Eurostile, sans-serif;
  letter-spacing: 0.5px;
  font-size: 110%;
}
</style>
