// import { getMidnight } from './functions/msTillMidnight';

export default {
  gapi: null,
  // initilises the library returns googleAuthObj
  async load(initCB) {
    // if (!window.gapi) return this.load();
    this.gapi = window.gapi;
    const loaded = async () => {
      initCB()
    }
    await this.gapi.load('client',loaded);
    return { success: true}
  },
  async initialise(loadedCB) {
    try {
      const init = async () => {
        await this.clientInit()
        loadedCB()
      }
      await this.load(init)
    } catch (e) {
      console.log(e)
      return { error: e };
    }
  },
  async clientInit() {
    try {
      await this.gapi.client.init({
        apiKey: process.env.VUE_APP_GAPI_API_KEY,
        // Your API key will be automatically added to the Discovery Document URLs.
        // discoveryDocs: ['https://people.googleapis.com/$discovery/rest'],
        // clientId and scope are optional if auth is not required.
        clientId: process.env.VUE_APP_GAPI_CLIENT_ID,
        scope: 'https://www.googleapis.com/auth/calendar.events.readonly',
      })
      return { success: true }
    } catch (e) {
      return { error: e };
    }
  },
  async getUpcomingEvents() {
    // let eventsCalendar = '75ii68embuo77tb48a3nlrjf3c'
    let serviceEmail = '75ii68embuo77tb48a3nlrjf3c@group.calendar.google.com'
    return this.gapi.client
      .request({
        'path': `https://www.googleapis.com/calendar/v3/calendars/${serviceEmail}/events`,
      })
      .then((res) => {
        return res.result.items;
      })
      .catch((error) => {
        console.log(error)
        return [];
      });
  }
};
