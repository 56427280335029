import gapi from '@/functions/googleCalendar.js';

const state = {
  // gallery -------------------------------------------------------------------
  rssFeedUrl: "https://zapier.com/engine/rss/9373700/matisa-bicycles",
  galleryPosts: [],
  currentLightboxPost: '',
  // events --------------------------------------------------------------------
  upcomingEventsFeedUrl: "https://zapier.com/engine/rss/9373700/matisa-upcoming-events",
  upcomingEvents: [],
  // collaborators -------------------------------------------------------------
  collaborators: [
    {
      name: 'Hamilton Wheelers',
      link: 'http://www.hamiltonwheelers.com/results/1676-13th-march-2021-nundah-results',
      logo: 'hamilton_wheelers.png'
    },
  ]
};

const getters = {
  // gallery -------------------------------------------------------------------
  getRssFeedUrl: (state) => state.rssFeedUrl,
  getGalleryPosts: (state) => state.galleryPosts,
  getIfAlreadyInGallery: (state) => (link) => {
    let dupes = state.galleryPosts.filter((post) => post.link === link)
    return dupes.length
  },
  getPostsWithoutItem: (state) => (link) => {
    let postsWithoutItem = state.galleryPosts.filter((post) => post.link !== link)
    return postsWithoutItem
  },
  getCurrentLightboxPost: (state) => state.currentLightboxPost,
  // events --------------------------------------------------------------------
  getUpcomingEventFeedUrl: (state) => state.upcomingEventsFeedUrl,
  getUpcomingEvents: (state) => state.upcomingEvents,
  // collaborators -------------------------------------------------------------
  getCollaborators: (state) => state.collaborators,
};

const mutations = {
  // gallery -------------------------------------------------------------------
  addGalleryPost(state, val) {
    state.galleryPosts.push(val)
  },
  setGalleryPosts(state, val) {
    state.galleryPosts = val
  },
  setCurrentLightboxPost(state, val) {
    state.currentLightboxPost = val;
  },
  // events --------------------------------------------------------------------
  addUpcomingEvent(state, val) {
    state.upcomingEvents = [val, ...state.upcomingEvents]
  },
  setUpcomingEvents(state, val) {
    state.upcomingEvents = val
  },
};

const actions = {
  // gallery -------------------------------------------------------------------
  async getInstaRssFeed({dispatch, getters}) {
    let feedUrl = getters.getRssFeedUrl
    fetch(feedUrl)
      .then(response => response.text())
      .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
      .then(data => {
        const items = data.querySelectorAll("item");
        // let postLinks = []
        items.forEach(el => {
          let post = {
            link: '',
            image: '',
            title: '',
            type: ''
          }
          post.image = el.getElementsByTagName('enclosure')[0].getAttribute('url')
          post.type = el.getElementsByTagName('enclosure')[0].getAttribute('type')
          post.title = el.getElementsByTagName('title')[0].innerHTML
          post.link = el.getElementsByTagName('link')[0].innerHTML
          dispatch('addGalleryPost', post)
        })
      })
  },
  async addGalleryPost({commit, getters}, val) {
    let ifAlreadyExists = getters.getIfAlreadyInGallery(val.link)
    if(!ifAlreadyExists) commit('addGalleryPost', val)
  },
  async removeGalleryPost({commit, getters}, val) {
    let postsWithoutError = getters.getPostsWithoutItem(val)
    commit('setGalleryPosts', postsWithoutError)
  },
  async openLightboxPost({commit},val) {
    commit('setCurrentLightboxPost', val)
  },
  async closeLightbox({commit}) {
    commit('setCurrentLightboxPost', '')
  },
  // events --------------------------------------------------------------------
  async getUpcomingEventsGapi({ dispatch, commit }) {
    commit('setUpcomingEvents', [])
    let events = await gapi.getUpcomingEvents()
    events.forEach(item => {
      dispatch('addUpcomingEvent', item)
    })
  },
  async addUpcomingEvent({commit, getters}, val) {
    let ifAlreadyExists = getters.getIfAlreadyInGallery(val.link)
    if(!ifAlreadyExists && val) {
      let eventItem = {
        id: val.id,
        title: val.summary,
        description: val.description,
        location: val.location,
        startTime: val.start?.dateTime,
        endTime: val.end?.dateTime
      }
      commit('addUpcomingEvent', eventItem)
    }
  },
  async removeUpcomingEvent({commit, getters}, val) {
    let postsWithoutError = getters.getPostsWithoutItem(val)
    commit('setUpcomingEvents', postsWithoutError)
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
