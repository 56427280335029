const state = {
  homeSections: [
    {
      id: 'previous-builds',
      type: 'gallery',
      text: 'OUR PREVIOUS BUILDS',
      size: 'full',
      folder: 'previous_builds_slider',
      images: [
        'previous2.jpeg',
        'previous1.jpeg',
        'previous3.jpg',
        'previous4.jpeg'
      ],
      // link: {
      //   type: 'internal',
      //   url: 'community',
      //   text: 'FIND OUT MORE'
      // }
    },
    {
      id: 'intro',
      type: 'link',
      text: 'ONE OF A KIND CUSTOM BUILT STEEL FRAMES',
      size: 'half',
      // img: 'banners/workshop.png',
      // link: {
      //   type: 'internal',
      //   url: 'workshop-tour',
      //   text: 'WORKSHOP TOUR'
      // }
    },
    {
      id: 'bike-fitting',
      type: 'link',
      text: 'GET THE PERFECT FIT',
      size: 'full',
      img: 'banners/fitting2.png',
      link: {
        type: 'internal',
        url: 'fitting',
        text: 'FIND OUT MORE'
      }
    },
    {
      id: 'workshop',
      type: 'link',
      text: '30 YEARS OF SPECIALIST EXPERIENCE',
      size: 'half',
      // img: 'banners/workshop.png',
      // link: {
      //   type: 'internal',
      //   url: 'workshop-tour',
      //   text: 'WORKSHOP TOUR'
      // }
    },
    {
      id: 'community',
      type: 'gallery',
      text: 'OUR COMMUNITY',
      size: 'full',
      folder: 'our_community_slider',
      images: [
        'community1.jpeg',
        'community4.jpg',
        'community3.jpeg',
        'community2.jpg'
      ],
      link: {
        type: 'internal',
        url: 'community',
        text: 'FIND OUT MORE'
      }
    },
    {
      id: 'socials',
      type: 'call-to-action',
      text: 'YOU CAN ALSO FIND US HERE',
      size: 'half',
      img: '',
      buttons: [
        {
          id: 'instagram',
          icon: 'socials/instagram.png',
          link: {
            type: 'external',
            url: 'https://www.instagram.com/matisa_bicycles/'
          }
        },
        // {
        //   id: 'youtube',
        //   icon: 'socials/youtube.png',
        //   link: {
        //     type: 'external',
        //     url: ''
        //   }
        // },
        {
          id: 'facebook',
          icon: 'socials/facebook.png',
          link: {
            type: 'external',
            url: 'https://www.facebook.com/matisabicycles'
          }
        }
      ]
    },
    // {
    //   id: 'previous-builds',
    //   type: 'gallery',
    //   text: 'OUR PREVIOUS BUILDS',
    //   folder: 'previous_builds_slider',
    //   images: [
    //     'gallery1.png',
    //     'gallery3.png',
    //     'gallery2.png',
    //     'gallery4.jpg'
    //   ]
    // },
    // {
    //   id: 'order-now',
    //   type: 'call-to-action',
    //   text: 'ORDER YOURS NOW',
    //   img: '',
    //   link: {
    //     type: 'internal',
    //     url: 'order',
    //     text: ''
    //   }
    // },
  ]
};

const getters = {
  getHomeSections: (state) => state.homeSections,
};

const mutations = {
  // setCurrentRoute(state, data) {
  //   state.currentRoute = data;
  // },
};

const actions = {
  // async calcRoomPositions(context, data) {
  // },
};

export default {
  state,
  getters,
  mutations,
  actions
};
